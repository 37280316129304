import {MenuItem} from './menu.model';
import {Constants} from "../../constants/constants";

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: "/dashboard",
        authorities: [Constants.ROLE_ADMIN, Constants.ROLE_USER, Constants.ROLE_PROJECT_MANAGER]
    },
    {
        id: 2,
        label: 'MENUITEMS.WORKING_ACTIVITIES-MENU',
        icon: 'bx bx-calendar',
        link: "/working-activities",
        authorities: [Constants.ROLE_ADMIN, Constants.ROLE_USER]
    },
    {
        id: 3,
        label: 'MENUITEMS.PROJECT_MANAGEMENT',
        icon: 'bx bx-calendar-check',
        authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER],
        subItems: [
            {
                id: 3,
                label: 'MENUITEMS.REVIEW-WORKING_ACTIVITIES-MENU',
                icon: 'bx bx-calendar-check',
                link: "/review-working-activities",
                authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER],
                parentId: 3
            },
            // {
            //     id: 5,
            //     label: 'MENUITEMS.REPORTS-MENU',
            //     icon: 'bx bxs-download',
            //     link: "/reports",
            //     authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER],
            //     parentId: 3
            // },
            {
                id: 6,
                label: 'MENUITEMS.WORKING_ACTIVITY_REPORT-MENU',
                icon: 'bx bx-file',
                link: "/working-activity-report",
                authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER]
            },
        ]
    },
    {
        id: 7,
        label: 'MENUITEMS.ADMINISTRATION',
        icon: 'bx bx-calendar-check',
        authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER],
        subItems: [
            {
                id: 8,
                label: 'MENUITEMS.USERS',
                icon: 'bx bx-group',
                link: "/users",
                parentId: 7,
                authorities: [Constants.ROLE_ADMIN]
            },
            {
                id: 9,
                label: 'MENUITEMS.PROJECTS-MENU',
                icon: 'bx bx-briefcase-alt-2',
                link: "/projects",
                parentId: 7,
                authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER]
            },
        ]
    },
    {
        id: 8,
        label: 'MENUITEMS.LOGS',
        icon: 'bx bx-history',
        link: "/logs",
        authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER]
    },
    {
        id: 8,
        label: 'MENUITEMS.CALENDAR.TEXT',
        icon: 'bx bx-calendar',
        link: "/calendar",
        authorities: [Constants.ROLE_ADMIN, Constants.ROLE_PROJECT_MANAGER, Constants.ROLE_USER]
    }
];

