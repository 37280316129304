export class Constants {

  public static  ROLE_ADMIN = "ADMIN"; // Constants.ROLE_ADMIN
  public static  ROLE_USER = "USER"; // Constants.ROLE_USER
  public static  ROLE_PROJECT_MANAGER = "PROJECT_MANAGER"; // Constants.ROLE_PROJECT_MANAGER

  public static ENTITY_STATUS_ACTIVE="active";
  public static ENTITY_STATUS_DELETED="deleted";
  public static ENTITY_STATUS_DISABLED="disabled";

  public static DATE_FORMAT = "dd-MM-yyyy"
  public static DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY"
  public static DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD"

  public static LOG_ENTITY_USER ="User";
  public static LOG_ENTITY_PROJECT ="Project";
  public static LOG_ENTITY_PROJECTMEMBER ="ProjectMember";
  public static LOG_ENTITY_WORKING_ACTIVITY ="WorkingActivity";
  public static LOG_ENTITY_WORKING_ACTIVITY_NOTE ="WorkingActivityNote";
  public static LOG_ENTITY_WORKING_ACTIVITY_REPORT ="WorkingActivityReport";
  public static LOG_ENTITY_CALENDAR_ACTIVITY ="CalendarActivity";

  public static LANGUAGE_RO = "ro";
  public static LANGUAGE_EN = "en";

  public static LANGUAGE_FULL_RO = "Romanian";
  public static LANGUAGE_FULL_EN = "English";

  public static COOCKIES_LANG = "lang";
}
